import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';


import { useHistory } from 'react-router-dom';
import OrderError from '../../pages/OrderError';
import WebsocketManager from '../../WebsocketManager';

import api from '../../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [websocketManager, setWebsocketManager] = useState(null);
  const [errorComponent, setErrorComponent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAction, setErrorAction] = useState([]);
  const [userToken, setUserToken] = useState(() => {
    const tokenStorage = localStorage.getItem('@waitermanager:userToken');

    if (tokenStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenStorage}`;
      return tokenStorage;
    }

    return '';
  });

  const [sessionKey, setSessionKey] = useState(() => {
    const sessionKeyStorage = localStorage.getItem('@waitermanager:sessionKey');

    if (sessionKeyStorage) {
      return sessionKeyStorage;
    }

    return '';
  });

  const [stoneDevice, setStoneDevice] = useState(() => {
    const stoneDeviceStorage = localStorage.getItem(
      '@waitermanager:stoneDevice',
    );

    if (stoneDeviceStorage) {
      return JSON.parse(stoneDeviceStorage);
    }

    return '';
  });

  const [restaurantId, setRestaurantId] = useState(() => {
    const restaurantIdStorage = localStorage.getItem(
      '@waitermanager:restaurantId',
    );

    if (restaurantIdStorage) {
      return restaurantIdStorage;
    }

    return '';
  });

  const [restaurantName, setRestaurantName] = useState(() => {
    const restaurantNameStorage = localStorage.getItem(
      '@waitermanager:restaurantName',
    );

    if (restaurantNameStorage) {
      return restaurantNameStorage;
    }

    return '';
  });

  const [restaurantHasStone, setRestaurantHasStone] = useState(() => {
    const restaurantNameStorage = localStorage.getItem(
      '@waitermanager:restaurantHasStone',
    );

    if (restaurantNameStorage) {
      return restaurantNameStorage;
    }

    return '';
  });

  const [restaurantFantasyName, setRestaurantFantasyName] = useState(() => {
    const restaurantFantasyNameStorage = localStorage.getItem(
      '@waitermanager:restaurantFantasyName',
    );

    if (restaurantFantasyNameStorage) {
      return restaurantFantasyNameStorage;
    }

    return '';
  });

  const [restaurantAvatar, setRestaurantAvatar] = useState(() => {
    const restaurantAvatarStorage = localStorage.getItem(
      '@waitermanager:restaurantAvatar',
    );

    if (restaurantAvatarStorage) {
      return restaurantAvatarStorage;
    }

    return '';
  });

  const [restaurantWithWaiterCloseBill, setRestaurantWithWaiterCloseBill] = useState(() => {
    const restaurantWithWaiterCloseBillStorage = localStorage.getItem(
      '@waitermanager:restaurantWithWaiterCloseBill',
    );

    if (restaurantWithWaiterCloseBillStorage) {
      return restaurantWithWaiterCloseBillStorage;
    }

    return false;
  });

  const [tableNumber, setTableNumber] = useState(() => {
    const tableNumberStorage = localStorage.getItem(
      '@waitermanager:tableNumber',
    );

    if (tableNumberStorage) {
      return tableNumberStorage;
    }

    return '';
  });

  const [tableKey, setTableKey] = useState(() => {
    const tableKeyStorage = localStorage.getItem('@waitermanager:tableKey');

    if (tableKeyStorage) {
      return tableKeyStorage;
    }

    return '';
  });

  const [tableStatus, setTableStatus] = useState(() => {
    const tableStatusStorage = localStorage.getItem(
      '@waitermanager:tableStatus',
    );

    if (tableStatusStorage) {
      return tableStatusStorage;
    }

    return '';
  });

  const [basketId, setBasketId] = useState(() => {
    const basketIdStorage = localStorage.getItem('@waitermanager:basketId');

    if (basketIdStorage) {
      return basketIdStorage;
    }

    return '';
  });

  const [tableId, setTableId] = useState(() => {
    const tableIdStorage = localStorage.getItem('@waitermanager:tableId');

    if (tableIdStorage) {
      return tableIdStorage;
    }

    return '';
  });

  const [restaurantServiceTax, setRestaurantServiceTax] = useState(() => {
    const restaurantServiceTaxStorage = localStorage.getItem(
      '@waitermanager:restaurantServiceTax',
    );

    if (restaurantServiceTaxStorage) {
      return restaurantServiceTaxStorage;
    }

    return '';
  });

  const [isBalcony, setIsBalcony] = useState(() => {
    const isBalconyStorage = localStorage.getItem('@waitermanager:isBalcony');

    if (isBalconyStorage) {
      return isBalconyStorage;
    }

    return '';
  });

  const [isPos, setIsPos] = useState(() => {
    const isPosStorage = localStorage.getItem('@waitermanager:isPos');

    if (isPosStorage) {
      return isPosStorage;
    }

    return '';
  });

  const [stoneTransactionId, setStoneTransactionId] = useState(() => {
    const isStoneTransactionStorage = localStorage.getItem('@waitermanager:stoneTransactionId');

    if (isStoneTransactionStorage) {
      return isStoneTransactionStorage;
    }

    return '';
  });

  const [restaurantHasPix, setRestaurantHasPix] = useState(() => {
    const restaurantHasPixStorage = localStorage.getItem('@waitermanager:restaurantHasPix');

    if (restaurantHasPixStorage) {
      return restaurantHasPixStorage;
    }

    return '';
  });

  const [restaurantUseCommand, setRestaurantUseCommand] = useState(() => {
    const restaurantUseCommandStorage = localStorage.getItem('@waitermanager:restaurantUseCommand');

    if (restaurantUseCommandStorage) {
      return restaurantUseCommandStorage;
    }

    return '';
  });

  const [buyerCreatedByWaiter, setBuyerCreatedByWaiter] = useState(() => {
    const buyerCreatedByWaiterStorage = localStorage.getItem('@waitermanager:buyerCreatedByWaiter');

    if (buyerCreatedByWaiterStorage) {
      return JSON.parse(buyerCreatedByWaiterStorage);
    }

    return '';
  });

  const [buyerSelectedByWaiter, setBuyerSelectedByWaiter] = useState(() => {
    const buyerSelectedByWaiterStorage = localStorage.getItem('@waitermanager:buyerSelectedByWaiter');

    if (buyerSelectedByWaiterStorage) {
      return JSON.parse(buyerSelectedByWaiterStorage);
    }

    return '';
  });

  const [buyersCreatedByWaiter, setBuyersCreatedByWaiter] = useState(() => {
    const buyersCreatedByWaiterStorage = localStorage.getItem('@waitermanager:buyersCreatedByWaiter');

    if (buyersCreatedByWaiterStorage) {
      return JSON.parse(buyersCreatedByWaiterStorage);
    }

    return '';
  });

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerSelectedByWaiter',
      JSON.stringify(buyerSelectedByWaiter),
    );
  }, [buyerSelectedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyerCreatedByWaiter',
      JSON.stringify(buyerCreatedByWaiter),
    );
  }, [buyerCreatedByWaiter]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:buyersCreatedByWaiter',
      JSON.stringify(buyersCreatedByWaiter),
    );
  }, [buyersCreatedByWaiter]);


  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:restaurantHasPix',
      restaurantHasPix,
    );
  }, [restaurantHasPix]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:restaurantUseCommand',
      restaurantUseCommand,
    );
  }, [restaurantUseCommand]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:restaurantWithWaiterCloseBill',
      restaurantWithWaiterCloseBill,
    );
  }, [restaurantWithWaiterCloseBill]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:isBalcony',
      isBalcony,
    );
  }, [isBalcony]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:isPos',
      isPos,
    );
  }, [isPos]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:stoneTransactionId',
      stoneTransactionId,
    );
  }, [stoneTransactionId]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:stoneDevice',
      JSON.stringify(stoneDevice),
    );
  }, [stoneDevice]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:restaurantServiceTax',
      restaurantServiceTax,
    );
  }, [restaurantServiceTax]);

  useEffect(() => {
    localStorage.setItem(
      '@waitermanager:restaurantFantasyName',
      restaurantFantasyName,
    );
  }, [restaurantFantasyName]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:tableId', tableId);
  }, [tableId]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:tableStatus', tableStatus);
  }, [tableStatus]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:basketId', basketId);
  }, [basketId]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:userToken', userToken);
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:sessionKey', sessionKey);
  }, [sessionKey]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:tableNumber', tableNumber);
  }, [tableNumber]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:tableKey', tableKey);
  }, [tableKey]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:restaurantId', restaurantId);
  }, [restaurantId]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:restaurantName', restaurantName);
  }, [restaurantName]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:restaurantAvatar', restaurantAvatar);
  }, [restaurantAvatar]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:restaurantHasStone', restaurantHasStone);
  }, [restaurantHasStone]);

  useEffect(() => {
    localStorage.setItem('@waitermanager:userToken', userToken);

    if (userToken && userToken !== 'null') {
      const manager = new WebsocketManager(userToken);

      manager.addMessageCallback(-1, (data) => {
        console.log('AuthContext: socket new data');
      });

      setWebsocketManager(manager);
    } else if (websocketManager) {
      websocketManager.close();
      setWebsocketManager(null);
    }
  }, [userToken]);

  const setRestaurant = useCallback(
    (id, name, fantasy_name, avatar, service_tax, has_pix, has_stone_pos, waiters_can_close_sessions, command) => {
      setRestaurantId(id);
      setRestaurantName(name);
      setRestaurantFantasyName(fantasy_name);
      setRestaurantAvatar(avatar);
      setRestaurantServiceTax(service_tax);
      setRestaurantHasPix(has_pix);
      setRestaurantUseCommand(command);
      setRestaurantHasStone(has_stone_pos);
      setRestaurantWithWaiterCloseBill(waiters_can_close_sessions)
    },
    [],
  );

  const waiterLogout = useCallback(() => {
    localStorage.removeItem('@waitermanager:userToken');

    setUserToken('');
  }, []);

  const setTable = useCallback((number, key) => {
    setTableNumber(number);
    setTableKey(key);
  }, []);

  const waiterLogin = useCallback(
    async (data) => {

      const emailTrim = data.email.trim()

      const response = await api.post('/public/sessions/waiter', {
        email: emailTrim,
        password: data.password,
      });

      const { user, token } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const command = user.restaurant.table_type === 'Comanda' ? true : false;

      setRestaurant(
        user.restaurant.id,
        user.restaurant.name,
        user.restaurant.fantasy_name,
        user.restaurant.avatar?.url,
        user.restaurant.has_service_tax,
        user.restaurant.has_pix,
        user.restaurant.has_stone_pos,
        user.restaurant.waiters_can_close_sessions,
        command
      );

      setStoneDevice(user.stone_device || null);

      setUserToken(token);
    },
    [setRestaurant],
  );

  const getRerstaurantInfo = useCallback(
    async () => {

      const response = await api.get('/waiter/show');

      const user = response.data;

      const command = user.restaurant.table_type === 'Comanda' ? true : false;

      setRestaurant(
        user.restaurant.id,
        user.restaurant.name,
        user.restaurant.fantasy_name,
        user.restaurant.avatar?.url,
        user.restaurant.has_service_tax,
        user.restaurant.has_pix,
        user.restaurant.has_stone_pos,
        user.restaurant.waiters_can_close_sessions,
        command
      );

      setStoneDevice(user.stone_device || null);

    },
    [setRestaurant],
  );

  useEffect(() => {
    getRerstaurantInfo()
  }, [getRerstaurantInfo])

  const getPos = useCallback(
    async () => {
      const response = await api.get('/waiter/pos');

      setStoneDevice(response.data);
    },
    [setStoneDevice],
  );

  const balconyLogin = useCallback(
    async (lastOrder, closeCart) => {
      try {
        const res = await api.post('waiter/balcony/orders', {
          order: lastOrder,
          table_id: Number(tableId),
          channel: 'gm'
        });

        const { orderBasket } = res.data;

        setBasketId(orderBasket.basket_id);

        closeCart()

        history.push('/confirmorder');
      } catch (err) {
        if (!err.response.ok) {
          if (err.response.data.error) {
            switch (err.response.data.error) {
              case 'Token inválido':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente',
                );
                waiterLogout();
                history.push(`/`);
                break;

              case 'Token não enviado':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente',
                );
                waiterLogout();
                history.push(`/`);
                break;

              default:
                break;
            }
          } else {
            switch (err.response.data.errorType) {
              case 'restaurant_closed':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse Restaurante está fechado. Volte novamente mais tarde.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'products_not_available':
                const products = err.response.data.payload;

                setErrorComponent(true);
                setErrorMessage(
                  `Olá, não poderemos concluir o seu pedido pois o item a seguir acabou de esgotar ou não está mais disponível: ${products.map(
                    item => ` ${item} `,
                  )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    `,
                );

                setErrorAction([
                  () => {
                    window.location.reload();
                  },
                ]);
                break;

              case 'table_session_closing':
                setErrorComponent(true);
                setErrorMessage(
                  'Esta mesa está em fechamento, favor solicitar a liberação no dashboard.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'wrong_product':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse produto não existe neste restaurante.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'table_not_found':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não encontrada, favor entrar na mesa novamente',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'wrong_restaurant_table':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não pertence a este restaurante, favor entrar na mesa novamente.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              default:
                setErrorComponent(true);
                setErrorMessage(
                  'O sistema está apresentando falha. Tente novamente mais tarde.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;
            }
          }
        }
      }
    },
    [tableId, history, waiterLogout],
  );

  const posLogin = useCallback(
    async (lastOrder, closeCart, type) => {
      try {
        const res = await api.post('waiter/pos/orders', {
          order: lastOrder,
          table_id: Number(tableId),
          type
        });

        console.log(res.data)

        const { orderBasket } = res.data;

        setBasketId(orderBasket.basket_id);

        setStoneTransactionId(res.data.transaction_id);

        closeCart();

        history.push('/waitingpayment');

      } catch (err) {
        if (!err.response.ok) {
          if (err.response.data.error) {
            switch (err.response.data.error) {
              case 'Token inválido':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente',
                );
                waiterLogout();
                history.push(`/`);
                break;

              case 'Token não enviado':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente',
                );
                waiterLogout();
                history.push(`/`);
                break;

              default:
                break;
            }
          } else {
            switch (err.response.data.errorType) {
              case 'restaurant_closed':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse Restaurante está fechado. Volte novamente mais tarde.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'products_not_available':
                const products = err.response.data.payload;

                setErrorComponent(true);
                setErrorMessage(
                  `Olá, não poderemos concluir o seu pedido pois o item a seguir acabou de esgotar ou não está mais disponível: ${products.map(
                    item => ` ${item} `,
                  )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    `,
                );

                setErrorAction([
                  () => {
                    window.location.reload();
                  },
                ]);
                break;

              case 'table_session_closing':
                setErrorComponent(true);
                setErrorMessage(
                  'Esta mesa está em fechamento, favor solicitar a liberação no dashboard.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'wrong_product':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse produto não existe neste restaurante.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'table_not_found':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não encontrada, favor entrar na mesa novamente',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              case 'wrong_restaurant_table':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não pertence a este restaurante, favor entrar na mesa novamente.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;

              default:
                setErrorComponent(true);
                setErrorMessage(
                  'O sistema está apresentando falha. Tente novamente mais tarde.',
                );
                setErrorAction([
                  () => {
                    history.push(`/restauranttables`);
                  },
                ]);
                break;
            }
          }
        }
      }
    },
    [tableId, history, waiterLogout],
  );

  const tableLogin = useCallback(
    async (lastOrder, command_table_number, closeCart) => {
      try {
        if (
          buyerSelectedByWaiter.id &&
          buyerSelectedByWaiter.id !== -33802 &&
          buyerSelectedByWaiter.id !== -33800
        ) {
          const res = await api.post('/waiter/buyer/orders', {
            order: lastOrder,
            table_id: Number(tableId),
            buyer_id: buyerSelectedByWaiter.id,
            command_table_number,
            channel: 'gm'
          });

          const { updatedBasket } = res.data;

          setBasketId(updatedBasket.basket_id);

          closeCart();

          history.push('/confirmorder');
        } else if (buyerCreatedByWaiter) {
          const buyer = await api.post('/waiter/buyer', {
            name: buyerCreatedByWaiter.name,
            phone: buyerCreatedByWaiter.phone,
            table_id: Number(tableId),
          });

          const res = await api.post('/waiter/buyer/orders', {
            order: lastOrder,
            table_id: Number(tableId),
            buyer_id: buyer.data.id,
            command_table_number,
            channel: 'gm'
          });

          const { updatedBasket } = res.data;

          setBasketId(updatedBasket.basket_id);

          closeCart();

          history.push('/confirmorder');
        } else {
          const res = await api.post('waiter/orders', {
            order: lastOrder,
            table_id: Number(tableId),
            command_table_number,
            channel: 'gm'
          });

          const { orderBasket } = res.data;

          setBasketId(orderBasket.basket_id);

          closeCart();

          history.push('/confirmorder');
        }
      } catch (err) {
        if (!err.response?.ok && err.response) {
          if (err.response.data.error) {
            switch (err.response.data.error) {
              case 'Token inválido':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente'
                );
                waiterLogout();
                history.push(`/`);
                break;

              case 'Token não enviado':
                alert(
                  'Seu login expirou, favor efetuar seu login novamente'
                );
                waiterLogout();
                history.push(`/`);
                break;

              default:
                break;
            }
          } else {
            switch (err.response.data.errorType) {
              case 'restaurant_closed':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse Restaurante está fechado. Volte novamente mais tarde.',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;

              case 'products_not_available':
                const products = err.response.data.payload;

                setErrorComponent(true);
                setErrorMessage(
                  `Olá, não poderemos concluir o seu pedido pois o item a seguir acabou de esgotar ou não está mais disponível: ${products.map(
                    item => ` ${item} `,
                  )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    `,
                );

                setErrorAction([
                  () => window.location.reload(),
                ]);
                break;

              case 'table_session_closing':
                setErrorComponent(true);
                setErrorMessage(
                  'Esta mesa está em fechamento, favor solicitar a liberação no dashboard.',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;

              case 'wrong_product':
                setErrorComponent(true);
                setErrorMessage(
                  'Esse produto não existe neste restaurante.',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;

              case 'table_not_found':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não encontrada, favor entrar na mesa novamente',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;

              case 'wrong_restaurant_table':
                setErrorComponent(true);
                setErrorMessage(
                  'Mesa não pertence a este restaurante, favor entrar na mesa novamente.',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;

              case 'buyer_with_open_session':
                setErrorComponent(true);
                setErrorMessage(
                  'Este cliente já possui conta aberta em uma outra mesa, favor verificar no dashboard',
                );

                setBuyerCreatedByWaiter('');
                setBuyerSelectedByWaiter('');
                setBuyersCreatedByWaiter((buyers) =>
                  buyers.filter((b) => b.id !== -33802)
                );
                setErrorAction([
                  () => window.location.reload(),
                ]);
                break;

              default:
                setErrorComponent(true);
                await api.post('/public/client/log-error', {
                  error: 'Garçom: ' + err.message,
                });
                setErrorMessage(
                  'Ocorreu um erro ao fazer o pedido. Tente novamente, por favor. Caso volte a ocorrer, faça logout do sistema e em seguida se logue novamente, ou chame o suporte para auxiliar. ',
                );
                setErrorAction([
                  () => history.push(`/restauranttables`),
                ]);
                break;
            }
          }
        }
        else {
          await api.post('/public/client/log-error', {
            error: 'Garçom: ' + err.message,
          });
        }

      }
    },
    [
      tableId,
      history,
      waiterLogout,
      buyerCreatedByWaiter,
      buyerSelectedByWaiter,
      setBuyerSelectedByWaiter,
      setBuyerCreatedByWaiter,
      setBuyersCreatedByWaiter,
    ],
  );

  return (
    <AuthContext.Provider
      value={{
        setUserToken,
        userToken,
        tableLogin,
        balconyLogin,
        posLogin,
        setRestaurant,
        setTable,
        tableNumber,
        setTableNumber,
        sessionKey,
        restaurantId,
        restaurantAvatar,
        restaurantName,
        restaurantFantasyName,
        restaurantServiceTax,
        setBasketId,
        setTableId,
        setSessionKey,
        basketId,
        tableStatus,
        setTableStatus,
        waiterLogin,
        waiterLogout,
        setErrorComponent,
        isBalcony,
        setIsBalcony,
        isPos,
        setIsPos,
        setRestaurantHasPix,
        restaurantHasPix,
        restaurantUseCommand,
        websocketManager,
        buyerCreatedByWaiter,
        setBuyerCreatedByWaiter,
        buyerSelectedByWaiter,
        setBuyerSelectedByWaiter,
        setBuyersCreatedByWaiter,
        buyersCreatedByWaiter,
        stoneDevice,
        setStoneDevice,
        stoneTransactionId,
        getPos,
        restaurantHasStone,
        setRestaurantUseCommand,
        restaurantWithWaiterCloseBill,
      }}
    >
      {children}
      <OrderError
        error={errorComponent}
        errorMessage={errorMessage}
        errorAction={errorAction}
      />
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    console.log('useAuth must be within a provider');
  }

  return context;
}

export { AuthProvider, useAuth };
